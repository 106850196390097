import React, { Component } from "react";
import homebkg from '../assets/homebkg.jpg';
import redboard from '../assets/redboard_background.jpg';
import codebkg from '../assets/code_background.jpg';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from "react-bootstrap/Jumbotron";
import {Link} from 'react-router-dom';
import Footer from './footer';
import githubLogo from '../assets/github_logo.png';
import linkedinLogo from "../assets/linkedin_logo_dark.png";
import mailIcon from "../assets/mail-icon.png";

class HomePage extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    
    state = { };

    render() {
        return(
            <body>
                <div className="w-100 d-flex align-items-end position-absolute" style={{top: "72vh"}}>
                    <Container className="fade-in fly-in">
                        <Row className = "mt-5">
                            <Col className="d-flex test2 justify-content-center">
                                <a target="_blank" href="mailto:aryan.kalia@uwaterloo.ca"><img className="invert icon" src={mailIcon} style={{height: '30px'}} /></a>
                                <a className="d-none d-lg-inline" target="_blank" href="mailto:aryan.kalia@uwaterloo.ca"><p className="contact-info d-inline text-bottom ml-2">aryan.kalia@uwaterloo.ca</p></a>
                            </Col>
                            <Col className="d-flex test2 justify-content-center">
                                <a target="_blank" href="https://github.com/manumanuk"><img className="invert icon" src={githubLogo} style={{height: '30px'}} /></a>
                                <a className="d-none d-lg-inline" target="_blank" href="https://github.com/manumanuk"><p className="contact-info d-inline text-bottom ml-2" style={{position: "relative", top: "2.4px"}}>manumanuk</p></a>
                            </Col>
                            <Col className="d-flex test2 justify-content-center">
                                <a target="_blank" href="https://www.linkedin.com/in/aryan-kalia/"><img className="invert icon" style={{height: '30px'}} src={linkedinLogo} /></a>
                                <a className="d-none d-lg-inline" target="_blank" href="https://www.linkedin.com/in/aryan-kalia/"><p className="contact-info d-inline text-bottom ml-2">aryan-kalia</p></a>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Jumbotron fluid className="home-bg text-center m-0" style={{height: '91vh', backgroundImage: `url(${homebkg})`}}>
                    <Container fluid className="my-5 fade-in fly-in">
                        <Row className="col">
                            <div className="my-5 w-100"></div>
                            <div className="my-1 w-100"></div>
                            <div className="my-3 w-100 d-sm-none d-md-none d-lg-none"></div>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <h1 className="display-1 strong-title">Aryan Kalia</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <p className="mb-4 white-p-text">Mechatronics Engineering Student @ UWaterloo | Machine Learning | Computer Vision | Embedded Development</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={1.5} md={1.5} sm={1.5}>
                                <Link to="/projects"><Button className="button-light py-2 px-4 mx-2">Projects</Button></Link>
                            </Col>
                            <Col lg={1.5} md={1.5} sm={1.5}>
                                <a target="_blank" href="/AryanKaliaResume.pdf" download><Button className="button-light py-2 px-4 mx-2">Resume</Button></a>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            <Footer pageName="Home"/>
            </body>
        );
    }
}

export default HomePage;
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/navbar'
import HomePage from './components/homePage'
import AboutPage from './components/aboutPage'
import NotFoundPage from './components/notFoundPage'
import CareerPage from './components/careerPage';
import ProjectsPage from './components/projectsPage';

function App() {
  return (
    <Router>
      <NavbarComponent />
      <div className="App">
        <Switch>
            <Route strict exact path="/" component={ HomePage } />
            <Route path="/about" component={ AboutPage } />
            <Route path="/software/:elementId" render={(props) => (<Redirect to={'/projects/' + props.match.params.elementId} />)}/>
            <Route path="/hardware/:elementId" render={(props) => (<Redirect to={'/projects/' + props.match.params.elementId} />)}/>
            <Route exact path="/projects" component={ ProjectsPage } />
            <Route path="/projects/:elementId" render={(props) => (<ProjectsPage {...props}/>) } />
            <Route exact path="/career" component={ CareerPage } />
            <Route path="/career/:elementId" render={(props) => (<CareerPage {...props}/>) } />
            <Route component={ NotFoundPage } />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Jumbotron from "react-bootstrap/Jumbotron";
import Footer from './footer';
import Post from './post';
import codebkg from '../assets/code.jpg';
import firebase from 'firebase/app';
import 'firebase/database';

class ProjectsPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            posts: null,
            filters: [],
            activeFilter: "All"
        };
    }

    componentDidMount() {
        const rootRef = firebase.database().ref('/posts/projects/');
        rootRef.once('value').then(
            snapshot => {
                var postList = snapshot.val();
                var filtersList = new Set();
                postList && postList.forEach(post => {
                    post.tags && post.tags.forEach(tag => filtersList.add(tag));
                });
                filtersList = Array.from(filtersList);
                filtersList.unshift("All");
                this.setState({
                    posts: postList,
                    filters: filtersList
                });
            }, error => {
                console.error(error);
            });
    }

    changeFilter(index) {
        this.setState({
            activeFilter: this.state.filters[index]
        }, () => {}, (err) => {
            console.error(err);
        });
    }

    render() {
        return(
            <body>
                <div className="d-flex align-items-center justify-content-center home-bg text-center m-0" style={{backgroundImage: `url(${codebkg})`, height: '25vh', backgroundPositionY: '40%'}}>
                <Container>
                    <Row>
                        <Col className="d-flex justify-content-center">
                            <br/>
                            <h1 className="display-2" style={{color: 'white'}}>Projects</h1>
                        </Col>
                    </Row>
                </Container>
                </div>
                <div className="pb-5" style={{backgroundColor: 'rgb(248, 248, 248)'}}>
                    <Container>
                        <Row className="pt-2">
                            <Col>
                                <p className="dark-p-text">Filter:&nbsp;
                                <ToggleButtonGroup type="radio" size="sm" name="filter" defaultValue={0}>
                                    {this.state.filters && Object.keys(this.state.filters).map((key, index) => (
                                        <ToggleButton className="button-filter" onClick={(e) => this.changeFilter(e.target.value)} key={index} value={index}>{this.state.filters[key]}</ToggleButton>
                                    ))}
                                </ToggleButtonGroup>
                                </p>
                            </Col>
                        </Row>
                        {this.state.posts && Object.keys(this.state.posts).map((key, index) => {
                            if (this.state.activeFilter != "All" &&
                                (!this.state.posts[key].tags || this.state.posts[key].tags.indexOf(this.state.activeFilter) == -1)) {
                                return null;
                            }
                            return (
                                <div>
                                <Post content={this.state.posts[key]} index={index} scrollTo={this.props.match.params.elementId} />
                                {index+1 != this.state.posts.length-1 && <div className="horizontal-divider" ></div>}
                                </div>
                            )
                        }
                        )}
                        <div id="test-bed"></div>
                    </Container>
                </div>
                <Footer pageName="Projects"/>
            </body>
        );
    }
}

export default ProjectsPage;
